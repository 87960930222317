<template>
  <v-card>
    <v-card-title class="pb-0">
      <span class="headline">Servcices Form</span>
    </v-card-title>
    <v-card-text class="py-0">
      <v-form ref="serviceForm" :value="value" @input="$emit('input', $event)">
        <v-container>
          <v-row>
            <v-col cols="12" sm="8" md="6">
              <v-text-field
                :value="name"
                label="Name"
                type="text"
                :rules="rules.required"
                @input="$emit('update:name', $event)"
              />
            </v-col>
            <v-col cols="12" sm="8" md="6">
              <v-text-field
                :value="fee"
                label="Fee"
                type="number"
                :rules="rules.required"
                @input="$emit('update:fee', $event)"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="8" md="6">
              <v-textarea
                :value="description"
                label="Description"
                :rules="rules.required"
                @input="$emit('update:description', $event)"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="primary darken-1"
        :disabled="loading"
        @click.stop="$emit('cancel', $event)"
        >Cancel</v-btn
      >
      <v-btn
        color="primary darken-1"
        :loading="loading"
        @click.stop="
          loading = !loading;
          $emit('save', $event);
        "
        :disabled="!value || loading"
      >
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "ServiceForm",
  inheritAttrs: false,
  props: [
    'value', 
    'name',
    'description',
    'fee'
  ],
  data() {
    return {
      loading: false,
      showPassword: false,
      rules: {
        required: [
          value => !!value || "Required."
        ],
      }
    }
  },
  computed: {
    isEdit() {
      return (this.mode === "edit");
    },
    isEditAndBlankPassword() {
      return this.isEdit && !this.password;
    }
  },
  methods: {
    reset() {
      this.$refs.serviceForm.reset();
      this.loading = false;
    },
    resetValidation() {
      this.refs.serviceForm.resetValidation();
    }
  }
};
</script>
