<template>
   <v-container fluid>
    <v-card class="elevation-5">
      <v-card-title color="primary">
        Services
        <v-spacer></v-spacer>
        <v-btn
        fab
        absolute
        top
        right
        dark
        x-large
        class="mt-4 ml-2"
        color="primary lighten-2"
        @click.stop="createService"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      </v-card-title>
       <v-dialog
        persistent
        retain-focus
        refs="servicesDialog"
        v-model="serviceFormDialog"
        max-width="750px"
      >
        <service-form
          ref="serviceForm"
          :mode="serviceFormMode"
          v-model="serviceFormValid"
          v-bind.sync="newService"
          @cancel.stop="cancelServiceForm"
          @save.stop="submitServiceForm"
        ></service-form>
      </v-dialog>
       <v-data-table
        :headers="headers"
        :items="services"
        item-key="id"
      >
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editService(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteService(item)" color="red">mdi-delete</v-icon>
        </template>
      </v-data-table>
    </v-card>
    <v-snackbar
      top
      right
      v-model="snackbar"
      :timeout="4000"
      :color="snackbarColor"
    >
      {{ snackbarText }}
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
   </v-container>
</template>
<script>
import { Firestore } from "../plugins/firebaseInit";
import ServiceForm from "../components/services/form";
export default {
  name: "Services",
  components: { ServiceForm },
  data() {
    return {
      serviceFormDialog: false,
      serviceFormValid: false,
      services: [],
      cacheServices: [],
      firestore: null,
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name"
        },
        {
          text: "Description",
          sortable: true,
          value: "description"
        },
        {
          text: "Fee",
          sortable: true,
          value: "fee"
        },
        { text: "", value: "actions" }
      ],
      footerProps: {
        disableItemsPerPage: true
      },
      newService: {
        id: "",
        name: "",
        description: "",
        fee: 0,
      },
      defaultService: {
        id: "",
        name: "",
        description: "",
        fee: 0,
      },
      scannerFormDialog: false,
      serviceFormMode: "",
      snackbar: false,
      snackbarText: "",
      snackbarColor: "info",
    }
  },
  created() {
    this.firestore = Firestore
      .collection("services")
      .onSnapshot(snapshot => {
        let entries = [];
        snapshot.forEach(doc => {
          let data = doc.data();
          data.id = doc.id;
          entries.push(data);
        });
        this.services = entries;
        this.cacheServices = entries;
      });
  },
  destroyed: function() {
    this.firestore();
  },
  methods: {
    cancelServiceForm() {
      this.serviceFormDialog = false;
      this.$nextTick(() => {
        this.$refs.userForm.reset();
      });
    },
    submitServiceForm() {
      let docRef = null;
      if (this.serviceFormMode == "new") {
        docRef = Firestore.collection("services").doc();
        this.snackbarText = "New Service created";
      } else {
        docRef = Firestore.collection("services")
                 .doc(this.newService.id);
        this.snackbarText = `The Service ${this.newService.name} is updated`;
      }
        docRef.set(
          {
            name: this.newService.name,
            fee: this.newService.fee,
            description: this.newService.description
          },
          { merge: true }
        )
        .then(() => {
          this.serviceFormDialog = false;
          this.snackbarColor = "success";
          this.snackbar = true;
          this.serviceFormValid = true;
        })
        .catch(error => {
          this.serviceFormDialog = false;
          this.snackbarText = error;
          this.snackbarColor = "error";
          this.snackbar = true;
          this.serviceFormValid = true;
        });
    },
    createService() {
      this.serviceFormMode = "new";
      this.newService = Object.assign({}, this.defaultService);
      this.serviceFormDialog = true;
    },
    editService(item) {
      this.newService.id = item.id;
      this.newService.name = item.name;
      this.newService.description = item.description;
      this.newService.fee = item.fee;
      this.serviceFormMode = "edit";
      this.serviceFormDialog = true;
    },
    deleteService(item) {
      if (window.confirm("Do you want to delete this service?")) {
        Firestore
          .collection("services")
          .doc(item.id)
          .delete()
          .then(() => {
            this.snackbarText = "Admin deleted";
            this.snackbarColor = "success";
            this.snackbar = true;
          })
          .catch(error => {
            this.snackbarText = error;
            this.snackbarColor = "error";
            this.snackbar = true;
          });
      }
    },
  }


}
</script>
